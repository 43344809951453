<div class="content-login">
  <div class="trama">
    <img class="margin-img" src="../../../assets/images/g10.svg" alt="">
  </div>


    <!--Inicio Form-->
    <mat-card class="card" fxLayout="column" fxLayoutAlign="space-between none">

        <div fxLayout="row" fxLayoutAlign="start center">
            <img src="../../../assets/images/Icon-Soul.svg" alt="" style="width: auto;">
        </div>

        <mat-card-content fxLayout="column" fxLayoutAlign="space-between none">
            <br>
            <br>
            <div class="title">
                <h1>Crear Contraseña </h1>
                <p>Ingresa tu nueva contraseña</p>
            </div>
            <br>
            <br>
            <div fxLayout="column" fxLayoutAlign="space-between none">

                <form fxLayout="column" [formGroup]="createPasswordForm" (ngSubmit)="createPassword()" style="min-height: 60%;">
                    <input id="username" style="display:none" type="text" name="fakeusernameremembered">
                    <input id="password" style="display:none" type="password" name="fakepasswordremembered">
                    <mat-form-field appearance="fill">
                        <mat-label>Contraseña </mat-label>
                        <input matInput appBlockCopyPaste (keydown.enter)="createPassword()" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Escribe tu contraseña" formControlName="password" required>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.password.errors"><span *ngIf="error.password.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </a>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Confirme Contraseña</mat-label>
                        <input matInput appBlockCopyPaste (keydown.enter)="createPassword()" (keypress)="omitSpecialChar($event)" [type]="hide ? 'password' : 'text'" placeholder="Confirme su contraseña" formControlName="confirmPassword" required>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.minlength">¡Aún falta! Recibimos mínimo 5 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 15 caracteres</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasNumber">Su contraseña debe tener un número</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasCapitalCase">Su contraseña debe tener una mayuscula</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSmallCase">Su contraseña debe tener una minuscula</span></mat-error>
                        <mat-error *ngIf="error.confirmPassword.errors"><span *ngIf="error.confirmPassword.errors.hasSpecialCharacters">Su contraseña debe tener un caracter especial</span></mat-error>
                        <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                        </a>
                    </mat-form-field>

                    <br>
                    <br>
                    <button mat-raised-button type="submit" color="primary" [disabled]='createPasswordForm.invalid'>Crear</button>
                    <a routerLink="/login">Volver a iniciar sesión</a>
                </form>
                <br>
                <br>
                <div class="color-purple" style="min-height: 20%;">
                  <div fxLayout="row" fxLayoutAlign="center center" class="col footer mt-5">Develop by Montechelo &nbsp;<span style="transform: scale(1)" class="fi-sr-heart"></span> &nbsp; {{anio}}</div>
                  <div fxLayout="row" fxLayoutAlign="center center" class="col footerv mt-1">Versión 2.0</div>
                </div>

            </div>
        </mat-card-content>

    </mat-card>


</div>
