import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PortalHealthComponent } from "./portal-health.component";


const routes: Routes = [
    { path: '', component: PortalHealthComponent},
    { path: 'solicitudes', loadChildren: () => import('./modules/requests/requests.module').then((m) => m.RequestsModule) },
    { path: 'consultas', loadChildren: () => import('./modules/search/search.module').then((m) => m.SearchModule) },
    { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },

];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PortalHealthRoutingModule {}
