import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthServicesService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-request-password-token',
  templateUrl: './request-password-token.component.html',
  styleUrls: ['./request-password-token.component.sass']
})
export class RequestPasswordTokenComponent implements OnInit {

  requestTokenForm:FormGroup;
  anio = new Date().getFullYear();
  public subscription : Subscription

  constructor(
    private fb:FormBuilder,
    private authService:AuthServicesService,
    private dialogRef:MatDialogRef<RequestPasswordTokenComponent>,
     ) {
    this.requestTokenForm = this.fb.group({
      identification:['',[Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
    })
  }

  get error(): any { return this.requestTokenForm.controls; }
  ngOnInit(): void {
  }

  omitSpecialChar(event: any){
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
   }
 /**
   * @author Lina Hueso
   * @createdate 10-01-2023
   * Metodo que envia el formulario al servicio
   */
   requestToken(){
      if(this.requestTokenForm.valid){
          this.authService.requestToken(this.requestTokenForm.value).subscribe( (response:any)=> {
            console.log(response);

            if (response.response === 'OK') {
              Swal.fire({
               html: `Por favor revisa tu correo ${response.mail}`,
               icon: 'success',
               title : 'Enviado',
               confirmButtonText: 'Aceptar'
             });
             this.dialogRef.close();

           }
          })
      }
   }


}
