<!--Inicio Form-->
<div class="content">
    <div class="title">
      <h1> Recuperación de Contraseña </h1>
      <p>Ingresa tu número de identificación para recuperar tu contreseña</p>
  </div>
  <br>
  <br>
  <div fxLayout="column" fxLayoutAlign="space-between none">

      <form fxLayout="column" [formGroup]="requestTokenForm" (ngSubmit)="requestToken()" style="min-height: 60%;">

          <mat-form-field appearance="fill">
              <mat-label>Identificación</mat-label>
              <input appBlockCopyPaste (keypress)="omitSpecialChar($event)" matInput type="text" placeholder="Escribe tu nombre de usuario" formControlName="identification" required>
              <mat-error *ngIf="error.identification.errors"><span *ngIf="error.identification.errors.required">¡Advertencia! Este campo es requerido</span></mat-error>
              <mat-error *ngIf="error.identification.errors"><span *ngIf="error.identification.errors.minlength">¡Aún falta! Recibimos mínimo 3 caracteres</span></mat-error>
              <mat-error *ngIf="error.identification.errors"><span *ngIf="error.identification.errors.maxlength">¡Cuidado! Solo puedes tener un máximo de 50 caracteres</span></mat-error>
          </mat-form-field>

          <br>
          <br>
          <div class="row mt-3">
            <div class="col">
                <button mat-stroked-button style="width: 100%;" mat-dialog-close>Cancelar</button>
            </div>
            <div class="col">
                <button mat-raised-button type="submit" style="width: 100%;" color="primary" [disabled]='requestTokenForm.invalid'>Solicitar</button>
            </div>
          </div>
      </form>
      <br>
      <br>
      <div>
          <div fxLayout="row" fxLayoutAlign="center center" class="col footer">Develop by Montechelo &nbsp;<span style="transform: scale(1)" class="fi-sr-heart"></span> &nbsp;{{anio}}</div>
          <div fxLayout="row" fxLayoutAlign="center center" class="col footerv">Versión 2.0</div>
      </div>

  </div>
</div>
