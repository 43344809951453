import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-portal-health',
  templateUrl: './portal-health.component.html',
  styleUrls: ['./portal-health.component.sass']
})
export class PortalHealthComponent implements OnInit {
  public userName: any;

	constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.userName = this.authService.decryptToken();
  }

}
