import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalHealthRoutingModule } from './portal-health-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { PortalHealthComponent } from './portal-health.component';
import { MatPaginatorModule } from '@angular/material/paginator';



@NgModule({
  declarations: [PortalHealthComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PortalHealthRoutingModule,
    MaterialModule,
    MatTabsModule,
    SharedModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatPaginatorModule

  ]
})
export class PortalHealthModule { }
