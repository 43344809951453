import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
const API_AUTH = environment.HEALTH_URL;

@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {

  constructor(private http:HttpClient, public jwtHelper:JwtHelperService ) { }

  getUser(){
    if(localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('user') || '{}');
    }else{
      return null;
    }
  }
  getToken(){
    if(localStorage.getItem('user')){
      return JSON.parse(localStorage.getItem('token')|| '{}');
    }else{
      return null;
    }
  }
  verifyTokent(): boolean{
    return !this.jwtHelper.isTokenExpired(this.getToken());
 }

  setStorageUser(resp:any){
    localStorage.setItem('user',JSON.stringify(resp.user));
    localStorage.setItem('token',JSON.stringify(resp.access_token));
  }

  login(entries:any){
   return this.http.post(`${API_AUTH}auth/login`,entries);
  }
  requestToken(params:any){
      return this.http.post(`${API_AUTH}auth/resetPassword`,params);
  }
  checkToken(token:any){
    return this.http.get(`${API_AUTH}check/token/${token}`);
  }
  createPassword(token:any,params:any){
    return this.http.post(`${API_AUTH}auth/updatePassword/${token}`,params);
  }

  systemIdentify(system:any){
     const apiSystems = JSON.parse(localStorage.getItem('system')|| '{}');
      return apiSystems
  }
  logout(){
    return this.http.get(`${API_AUTH}auth/logout`);
  }

  decrypToken(){
    const token = JSON.parse(localStorage.getItem('token')|| '{}');
    return this.jwtHelper.decodeToken(token);
  }
  systemKey(){
    const apiSystems = JSON.parse(localStorage.getItem('system')|| '{}');
    const systemKey = apiSystems.find((element: any) => element.system_key);
    return systemKey.system_key;

  }
}

