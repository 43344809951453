import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe,  registerLocaleData } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { BasicSnackbarComponent } from './basic-snackbar/basic-snackbar.component';

@NgModule({
  declarations: [
    BasicSnackbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    BasicSnackbarComponent,
    CommonModule
  ],

  providers: [ CurrencyPipe, DecimalPipe],
})

export class SharedModule { }
