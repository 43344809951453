import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServicesService } from '../services/auth.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.sass']
})
export class CreatePasswordComponent implements OnInit {

  createPasswordForm:FormGroup;
  anio = new Date().getFullYear();
  hide: boolean = true;
  token;
  constructor(private fb:FormBuilder,private authService:AuthServicesService,private activeRoute:ActivatedRoute,
              private router:Router) {
            this.token = this.activeRoute.snapshot.paramMap.get('token');

            this.createPasswordForm = this.fb.group({
              'password':['',[
                this.passwordValidate(),
                Validators.required,
                Validators.maxLength(15),
                Validators.minLength(3)]],
              'confirmPassword':['',
                  [
                    this.passwordValidate(),
                    Validators.required,
                    Validators.maxLength(15),
                    Validators.minLength(5),
                    this.matchValues('password')]
                  ]
            })
  }
  get error(): any { return this.createPasswordForm.controls; }

  omitSpecialChar(event:any): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
   }

  ngOnInit(): void {
    this.createPasswordForm = this.fb.group({
      'password':['',[
        this.passwordValidate(),
        Validators.required,
        Validators.maxLength(15),
        Validators.minLength(3)]],
      'confirmPassword':['',
          [
            this.passwordValidate(),
            Validators.required,
            Validators.maxLength(15),
            Validators.minLength(5),
            this.matchValues('password')]
          ]
    })
  }


  matchValues( matchTo: string ): (AbstractControl:any) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === (control?.parent?.controls as { [key: string]: AbstractControl })[matchTo].value ? null : { isMatching: true };
    };
}

  /** controls[matchTo].value
   * @author Daniel Martinez
   * @createdate 2021-02-16
   * Metodo que se encarga de las validaciones del campo password
   */
   passwordValidate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!/[A-Z]/.test(control.value)) {
        return { hasCapitalCase: { value: control.value } };
      }
      else if (!/[a-z]/.test(control.value)) {
        return { hasSmallCase: { value: control.value } };
      }
      else if (!/[!@#$%^&*()_+=[{};':"|,.<>/?/{};':"|,.<>/?-]/.test(control.value)) {
        return { hasSpecialCharacters: { value: control.value } };
      }
      else if (!/\d/.test(control.value)) {
        return { hasNumber: { value: control.value } };
      }
      return null;
    };
  }

   /**
   * Metodo que permite validar si la  segunda contraseña coincide con la anterior
   *
   * @param matchTo Parametro al que se quiere hacer referencia, por ejemplo password
   * @returns isMatching boolean
   * @author Carlos Galindez
   */


  createPassword(){
        if(this.createPasswordForm.valid){
          let params = {
            'password':this.createPasswordForm.value.password
          }
          this.authService.createPassword(this.token,params).subscribe( (resp) => {
            this.router.navigateByUrl('/auth/login');
          });
        }
  }

}
