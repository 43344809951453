import { RequestPasswordTokenComponent } from './../request-password-token/request-password-token.component';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServicesService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})

export class LoginComponent implements OnInit {

  loginForm:FormGroup;
  hide: boolean = true;
  anio: number = 2022;
  publicIP: any;

  constructor(
    private fb: FormBuilder,
    private authService:AuthServicesService,
    private router:Router,
    public dialog: MatDialog
    ) {
   }

  get error(): any { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      identification:['',[Validators.required, Validators.maxLength(50), Validators.minLength(3)]],
      password:['',[Validators.required, Validators.maxLength(15), Validators.minLength(5)]]
   });
  }

  loginAuth(): any{
      if(this.loginForm.valid){
          this.authService.login(this.loginForm.value).subscribe( (resp:any) =>{
            this.authService.setStorageUser(resp);
            this.router.navigate(['/inicio']);
          });
      }
  }

  omitSpecialChar(event: any): any{
    let k;
    k = event.charCode;
    return((k > 32 && k < 126));
   }

   /**
	 * @author Lina Hueso
	 * @createdate 2022-12-27
	 * Metodo que abre el modal del componente de forgot-password
	 */
 forgotPassword(): any {
    const forgotPassword = this.dialog.open(RequestPasswordTokenComponent, {
      width: '40%',
      height: '88%',
      data: {
        type: 'cambiar',
        title: '¿Tienes problemas para iniciar sesión?',
      },
      panelClass: 'custom-dialog-container'
    });

    forgotPassword.afterClosed().subscribe(()=>{
      forgotPassword.close();
    });
  }
}
